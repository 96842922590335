import React from "react";
import {
  Paper,
  Link as MaterialLink,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import { News, hideNews, selectShowEmbeddedTweet, selectDismissDisableTimeout, selectFadeTimeout } from "../reducers/newsReducer";
import { DateTime } from "luxon";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TwitterIcon from "@mui/icons-material/Twitter";
import { yellow, red } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { useDispatch, useSelector } from "react-redux";
import { addSymbol } from "../reducers/symbolReducer";
import CloseIcon from '@mui/icons-material/Close';
import { selectEnableSpeaking } from "../reducers/authorizationSlice";
import { Tweet } from 'react-tweet'

import { getTweet,  Tweet as TweetType } from 'react-tweet/api'
import { DragHandle, Handshake, SentimentDissatisfied, SentimentDissatisfiedSharp, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVeryDissatisfied, TrendingDownOutlined, TrendingFlatOutlined, TrendingUp, TrendingUpOutlined } from "@mui/icons-material";

const synth = window.speechSynthesis;

interface ComponentProps {
  item: News;
  index: number;
}

interface KeyMap {
  slug: string;
  symbol: string;
}

let keywordMap: KeyMap[] = [
  { slug: "btc", symbol: "BTC" },
  { slug: "bitcoin", symbol: "BTC" },
  { slug: "sol", symbol: "SOL" },
  { slug: "solana", symbol: "SOL" },
  { slug: "xrp", symbol: "XRP" },
  { slug: "ripple", symbol: "XRP" },
];

const formatTime = (timeVariance: number) => {
  if (timeVariance < 119999) {
    return (timeVariance / 1000.0).toFixed(0) + "s ago";
  } else if (timeVariance < 60000 * 60) {
    return (timeVariance / 60000.0).toFixed(0) + "m ago";
  } else {
    return "ages ago";
  }
};

function NewsItem(props: ComponentProps) {
  const dispatch = useDispatch();

  const showEmbeddedTweet = useSelector(selectShowEmbeddedTweet);
  const dismissDisableTimeout = useSelector(selectDismissDisableTimeout);
  const fadeTimeout = useSelector(selectFadeTimeout);
  const enableSpeaking = useSelector(selectEnableSpeaking);

  const [date, setDate] = React.useState<number>(new Date().valueOf());

    const isZoomerColour = (props.item.title.startsWith("*") || props.item.title.endsWith("RTRS")) && props.item.type !== "Twitter"; 

  React.useEffect(() => {
    let interval = setInterval(() => {
      setDate(new Date().valueOf());
    }, 1000);

    if (enableSpeaking) {
        if (props.item.timestamp > (new Date().valueOf() - 5000.0)) {

            let spokenText = props.item.title;
            if (props.item.type === "Twitter") {
                spokenText += " tweeted";
            }
            //if (props.item.content) 
            //{
                //spokenText += " " + props.item.content;
            //}
    
            spokenText = spokenText.replace(/(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)/ig, "");
            spokenText = spokenText.replace(/g@/, "");
    
            //console.log(spokenText);
    
            const utterThis = new SpeechSynthesisUtterance(spokenText);
            utterThis.pitch = 1.4;//pitch.value;
            utterThis.rate = 1.7; //rate.value;
            synth.speak(utterThis);
        }
    }
    
    return () => {
      clearInterval(interval);
    };
  }, []);

  const extractKnownWords = (content: string) => {
    return content;
    /*
    return (
      <>
        {content.split(" ").map((text: string) => {
          let matchFound = keywordMap.find(
            (m) =>
              text.toLowerCase().replace("#", "").replace("$", "") === m.slug
          );
          return matchFound ? (
            <>
              {text} [
              <Link
                href="#"
                onClick={() => {
                  dispatch(addSymbol({symbol: matchFound?.symbol || "", pinned: true}));
                }}
              >
                #{matchFound.symbol}
              </Link>
              ]&nbsp;
            </>
          ) : (
            text + " "
          );
        })}
      </>
    );
    */
  };

  const [hideText, setHideText] = React.useState(false);

  //useHotkeys("1", () => { if (props.index === 0) { if (props.item.coins?.length || 0 > 0) { dispatch(addSymbol({ symbol: props.item.coins[0], pinned: true })); } } }, [props.index]);
  //useHotkeys("2", () => { if (props.index === 0) { if (props.item.coins?.length || 0 > 1) { dispatch(addSymbol({ symbol: props.item.coins[1], pinned: true })); } } }, [props.index]);
  //useHotkeys("3", () => { if (props.index === 0) { if (props.item.coins?.length || 0 > 2) { dispatch(addSymbol({ symbol: props.item.coins[2], pinned: true })); } } }, [props.index]);
  //useHotkeys("4", () => { if (props.index === 0) { if (props.item.coins?.length || 0 > 3) { dispatch(addSymbol({ symbol: props.item.coins[3], pinned: true })); } } }, [props.index]);
  //useHotkeys("5", () => { if (props.index === 0) { if (props.item.coins?.length || 0 > 4) { dispatch(addSymbol({ symbol: props.item.coins[4], pinned: true })); } } }, [props.index]);

  if (props.item.type === "Twitter" && showEmbeddedTweet) {
    /*
    getTweet(props.item.id || "").then((tweet: TweetType | undefined) => {
        console.log(tweet);
    });
    */
  }

  return (
    <Paper
      elevation={3}
      sx={{
        m: 1,
        p: 1,
        //border: `${(date - props.item.timestamp) > fadeTimeout * 1000 ? 0 : 2}px solid ${props.item.important ? yellow[600] : "#a9a9a9"}`,
        // boxShadow: `inset 0 0 ${(date - props.item.timestamp) > fadeTimeout * 1000 ? 0 : 3}px ${props.item.important ? yellow[600] : isZoomerColour ? red[600] : "#a9a9a9"}`,
        background: props.item.important ? undefined : undefined,
        color: props.item.important ? yellow[600] : isZoomerColour ? red[400] : undefined,
        opacity: (date - props.item.timestamp) > fadeTimeout * 1000 ? 0.5 : 1
      }}
    >
      <Box sx={{display:'flex', alignItems: 'top', justifyContent: 'space-between'}}>
        <Box key={1}>
        <Box sx={{display:'flex', alignItems: 'top', justifyContent: 'space-between'}}>
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 0 }}>
            <MaterialLink
              href={props.item.uri}
              target="_blank"
              sx={{
                color: props.item.important ? yellow[600] : isZoomerColour ? red[400] : "#fff",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
                <span>
              {props.item.type === "Twitter" ? (
                <TwitterIcon sx={{ fontSize: "1em", mr: 0.5 }} />
              ) : null}
              {props.item.title}{" "}
              <OpenInNewIcon sx={{ fontSize: "1em", ml: 0.5 }} />
              </span>
            </MaterialLink>
                
          </Typography>
          </Box>
          {props.item.rebroadcast ? (
          <Typography
            variant="body2"
            color="secondary"
            sx={{ fontSize: "0.7em", mb: 1 }}
          >
            {props.item.rebroadcast}
          </Typography>
        ) : (
          ""
        )}
        
        <Typography
        variant="body2"
        color="text.secondary"
        sx={{ fontSize: "0.7em", mb: 1 }}
      >
        {DateTime.fromMillis(props.item.timestamp).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )}
        &nbsp; ({formatTime(date - props.item.timestamp)})
      </Typography>


        </Box>

          <IconButton
           key={2}
            sx={{height: '40px'}}
            onClick={() => {dispatch(hideNews(props.item.timestamp))}}
            disabled={props.item.timestamp > new Date().valueOf() - (dismissDisableTimeout * 1000)}
          >
            <CloseIcon />
            </IconButton>
        </Box>
        
      
      
      
      {(props.item.coins?.length || 0) > 0
          ? 
          <Box sx={{mb: 1}}>
            {
          (props.item.coins ?? []).map((c: string, cIndex: number) => {
              return (
                <>
                  <Link
                    to="/"
                    style={{textDecoration: 'none'}}
                    onClick={() => {
                      dispatch(addSymbol({ symbol: c || "", pinned: true }));
                    }}
                  >
                    <Button
                        variant="contained"
                        sx={{
                        //    color: blueGrey[500],
                            textDecoration: "none",
                            fontWeight: 500,
                            mr: 2,
                            mt: 1,
                            mb: 1,
                        }}
                    >
                      {c}
                      {/*props.index === 0 ? (" [" + (cIndex + 1) + "]") : "" */}
                    </Button>
                  </Link>
                </>
              );
            })}
            </Box>
          : null}

            

      {
        props.item.type === "Twitter" && showEmbeddedTweet
        
        ? 

        <>

        {
            hideText ? null : 
            <Typography variant="body2" sx={{ overflowWrap: "break-word" }}  >
                {props.item.content ? (
                    <div dangerouslySetInnerHTML={{ __html: props.item.content.replace(/\\&quot;/g, "").replace(/\\\"/g, "") }} />
                ) : (
                ""
                )}
            </Typography>
        }
      
      <TwitterTweetEmbed
            tweetId={props.item.id || ""}
            options={{theme: "dark"}}
            onLoad={(element:any) => { if (element) { setHideText(true); } }}
        />

        </>

        : null

/*
<div data-theme="dark" data-include-replies={true}>
        <Tweet id={props.item.id || ""} data-tweet-body-font-size="1.0rem" fallback={} /></div> 
       */


      }

      {
        <Typography variant="body2" color="text.secondary"><b>ID: </b>{props.item.id || "NO ID!"}</Typography>
      }

      {
        props.item.sentiment 
        ?
        <>
        {
            props.item.sentiment.translation
            ? <Typography variant="body2" color="text.secondary"><b>EN: </b>{props.item.sentiment.translation}</Typography>
            : null
        }
        <Divider sx={{mt: 1, mb: 2}} />
        <Box sx={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}} component="div">

            <Tooltip title={props.item.sentiment.buyingJustification ? `${props.item.sentiment.buyingSuggestion.join(' | ')} ${props.item.sentiment.buyingJustification}` : `No buying suggestions`}>
            <Typography
                variant="body2"
                color={ props.item.sentiment.bullish && props.item.sentiment.buyingDirection === "SHORT" && props.item.sentiment.buyingSuggestion.length > 0 ? "error.main" : props.item.sentiment.bullish && props.item.sentiment.buyingDirection === "LONG" && props.item.sentiment.buyingSuggestion.length > 0 ? "success.main" : "text.secondary" }
                sx={{ fontSize: "0.7em", mb: 1 }}
            >
                {
                    props.item.sentiment.bullish && props.item.sentiment.buyingDirection === "LONG" && props.item.sentiment.buyingSuggestion.length > 0 ? <TrendingUpOutlined /> : props.item.sentiment.bullish && props.item.sentiment.buyingDirection === "SHORT" && props.item.sentiment.buyingSuggestion.length > 0 ? <TrendingDownOutlined /> : <TrendingFlatOutlined />
                }
            </Typography>
            </Tooltip>

            <Tooltip title={`${props.item.sentiment.sentiment} sentiment`}>
            <Typography
                variant="body2"
                color={ props.item.sentiment.sentiment === "NEUTRAL" ? "text.secondary" : props.item.sentiment.sentiment === "POSITIVE" ? "success.main" : "error.main" }
                sx={{ fontSize: "0.7em", mb: 1 }}
            >
                {
                    props.item.sentiment.sentiment === "NEUTRAL" ? <SentimentDissatisfied /> : props.item.sentiment.sentiment === "POSITIVE" ? <SentimentSatisfiedAlt /> : <SentimentVeryDissatisfied />
                }
            </Typography>
            </Tooltip>

            <Tooltip title={props.item.sentiment.partnershipJustification || "No partnership"}>
            <Typography
                variant="body2"
                color={ props.item.sentiment.partnership ? "success.main" : "text.secondary" }
                sx={{ fontSize: "0.7em", mb: 1 }}
            >
                {props.item.sentiment.partnership ? <Handshake /> : <DragHandle />}
            </Typography>
            </Tooltip>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "0.7em", mb: 1 }}
          >
            {props.item.sentiment.processingMillis}ms
          </Typography>
        </Box>
        </>
        
        : null
      }
      
      
    </Paper>
  );
}

export default NewsItem;
