import React from "react";
import {
    Box,
    Paper,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Switch,
} from "@mui/material";

import { blue } from "@mui/material/colors";
import { connectionEstablished } from "./reducers/newsReducer";
interface ComponentProps {
}

interface UpbitMarket {
    market: string,
}

interface BinanceCoin {
    coin: string,
    createTime: number,
    mcap: number,
}

interface MarketCap {
    coin: string,
    marketCap: number,
}

const usdtLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    //minimumSignificantDigits: 0,
    //maximumSignificantDigits: 0,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const binanceSpotUri = "https://api.binance.com/api/v3/exchangeInfo";
const binancePerpsUri = "https://fapi.binance.com/fapi/v1/exchangeInfo";
const upbitUri = 'https://api.upbit.com/v1/market/all';


function ResearchBinanceSpotOnUpbit(props: ComponentProps) {
    
    const [spotCoins, setSpotCoins] = React.useState<BinanceCoin[]>([]);
    const [perpsCoins, setPerpsCoins] = React.useState<BinanceCoin[]>([]);
    const [marketCaps, setMarketCaps] = React.useState<MarketCap[]>([]);
    const [upbitKRWMarkets, setUpbitKRWMarkets] = React.useState<String[]>([]);
    const [upbitBTCMarkets, setUpbitBTCMarkets] = React.useState<String[]>([]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binancePerpsUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {
                if (s.contractType === "PERPETUAL" && coins.findIndex(c => c.coin === s.baseAsset) === -1 && s.underlyingType === "COIN") {
                    coins.push({
                        coin: s.baseAsset.replace("1000", ""),
                        createTime: s.onboardDate,
                        mcap: 0
                    });
                }
            });
            setPerpsCoins(coins.sort((a, b) => a.createTime > b.createTime ? -1 : 1));
        });

        
        
    }, [, ]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binanceSpotUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {

                if (s.status !== "TRADING") {
                    return;
                }

                if (coins.findIndex(c => c.coin === s.baseAsset) === -1) {
                    coins.push({
                        coin: s.baseAsset,
                        createTime: s.createTime,
                        mcap: 0
                    });
                }
            });
            setSpotCoins(coins);
        });

        
        
    }, [, ]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch("https://news.kryptrader.com/mcap", {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let working: MarketCap[] = [];
            data.forEach((s: any) => {
                let existingIndex = working.findIndex(c => c.coin === s.symbol.toUpperCase());
                if (existingIndex === -1) {
                    working.push({
                        coin: s.symbol.toUpperCase(),
                        marketCap: s.market_cap,
                    });
                }
                else {
                    working[existingIndex].marketCap = Math.max(working[existingIndex].marketCap, s.market_cap);
                }
            });
            setMarketCaps(working);
        });

        
        
    }, [, ]);

    React.useEffect(() => {
    
        fetch(upbitUri, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            //console.log('data', data);

            let krwMarkets:String[] = data.filter((m: UpbitMarket) => { return m.market.startsWith('KRW-') })
                .map((m: UpbitMarket) => { return m.market.replace('KRW-', '') });
            setUpbitKRWMarkets(krwMarkets);

            let btcMarkets:String[] = data.filter((m: UpbitMarket) => { return m.market.startsWith('BTC-') })
                .map((m: UpbitMarket) => { return m.market.replace('BTC-', '') });
            setUpbitBTCMarkets(btcMarkets);
          });
    }, [, ])

    //console.log('perpsCoins', perpsCoins);

    let spot = spotCoins
        .filter(s => !s.coin.endsWith("DOWN") && !s.coin.endsWith("UP") && !s.coin.endsWith("BULL") && !s.coin.endsWith("BEAR") && !s.coin.endsWith("USD") && !s.coin.startsWith("USD") && !s.coin.endsWith("BTC") && !s.coin.endsWith("ETH") && !s.coin.endsWith("USDT"))
        .map(s => { return {coin: s.coin, createTime: s.createTime, mcap: marketCaps.find(m => m.coin === s.coin)?.marketCap || 0} })
        .filter(s => perpsCoins ? perpsCoins.findIndex(p => p.coin === s.coin) === -1 : true)
        .filter(s => upbitKRWMarkets ? upbitKRWMarkets.includes(s.coin) : true)
        //.filter(s => upbitBTCMarkets ? upbitBTCMarkets.includes(s.coin) : true)
        .sort((a, b) => a.mcap > b.mcap ? -1 : 1);

    //console.log('spot', spot);

    return (
        <Box>
            {
                spot.length > 0
                ? 
                <Box >
                <Typography sx={{fontWeight: 500, pl: 2, mb: 2, mt: 2}} variant="body2">Binance coins (Spot, no perps)</Typography>
                <Paper elevation={3} sx={{m: 2, mt: 0, p: 2, pt: 0}}>
                    
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Coin</TableCell>
                                        <TableCell sx={{textAlign: "right"}}>Market cap</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        spot.map(l => {
                                            return (
                                                <TableRow key={l.coin}>
                                                    <TableCell>{l.coin}</TableCell>
                                                    <TableCell sx={{textAlign: "right"}}>{usdtLocale.format(l.mcap)}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    </Box>
                : <Typography>Loading coins ...</Typography>
            }

            <Typography sx={{m: 2, borderBottom: "1px solid gray"}}>Notes</Typography>
            <Typography sx={{ml: 2}} variant="body2">
                Binance perps coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binancePerpsUri}</Typography>.<br /><br />
                Binance spot coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binanceSpotUri}</Typography>.<br /><br />
                Upbit API data from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{upbitUri}</Typography> filtered by markets starting with "KRW-" or "BTC-".<br /><br />
                Underlying type for perps must be "COIN" (not "INDEX") and contract names starting with "1000" were corrected.
            </Typography>
        </Box>
    );
}

export default ResearchBinanceSpotOnUpbit;
