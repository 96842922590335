import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectLiveTradeSymbols } from "./reducers/terminalReducer";
import TradeContainer from "./TradeContainer";

function Trades() {
  const liveTradeSymbols = useSelector(selectLiveTradeSymbols, shallowEqual);
  
  // console.log('did liveTradeSymbols change?', liveTradeSymbols);

  return (
    <>
      {
        [...liveTradeSymbols].reverse().map((t) => {
            return <TradeContainer symbol={t} key={t} />;
        })}
    </>
  );
}

export default React.memo(Trades);
