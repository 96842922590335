import React from "react";
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
} from "@mui/material";

import { News } from "./reducers/newsReducer";
import { Market, MarketPump } from "./types/Market";
import PumpCharts from "./components/PumpCharts";
import PumpTable from "./components/PumpTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Price, addCurrentPrice, selectPrices, selectCurrentPrices } from "./reducers/priceReducer";
import { DateTime } from "luxon";
import { RootState } from "./store";
import { addSymbol } from "./reducers/symbolReducer";

interface ComponentProps {
}

interface UpbitMarket {
    market: string,
    english_name: string,
    korean_name: string,
}

const getKPumpPrice = async (coin: string): Promise<number> => {
    try {
        const response = await fetch(`https://api.upbit.com/v1/trades/ticks?count=1&market=KRW-${coin}&to=23:50:00&daysAgo=1`, {
            method: "GET",
            mode: "no-cors",
        });
        const data = await response.json();
        return data[0].trade_price;
    } catch (error) {
        console.error("Error fetching price:", error);
        return 0;
    }
};

function KPumps(props: ComponentProps) {

    const dispatch = useDispatch();

    const currentPrices = useSelector(selectCurrentPrices);

    const [markets, setMarkets] = React.useState<Market[]>([]);
    const [pumps, setPumps] = React.useState<MarketPump[]>([]);
    const pumpsRef = React.useRef(pumps);

    const [minVolume, setMinVolume] = React.useState<number>(20000);
    const [krwRate, setKrwRate] = React.useState<number>(1295.5161);

    const btcPrices:Price[] = useSelector(
        (state) => selectPrices(state as RootState, "BTC"),
        shallowEqual
      );

    const [latestUpdate, setLatestUpdate] = React.useState<number>(new Date().valueOf());
    const latestUpdateRef = React.useRef(latestUpdate);

    const [percentPumps, setPercentPumps] = React.useState<MarketPump[]>([]);
    const [volumePumps, setVolumePumps] = React.useState<MarketPump[]>([]);

    // Get a list of all Bybit perps markets from https://api.bybit.com/v5/market/instruments-info?category=linear&limit=1000
    const [bybitMarkets, setBybitMarkets] = React.useState<String[]>([]);

    React.useEffect(() => {
        console.log('KPumps bybit markets update');

        fetch('https://api.bybit.com/v5/market/instruments-info?category=linear&limit=1000', {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                let bybitMarkets:String[] = data.result.list.map((m: any) => { return m.baseCoin
                    .replace("1000000", "")
                    .replace("100000", "")
                    .replace("10000", "")
                    .replace("1000", "")
                    .replace("100", "")
                    .replace("10", "")
                 });
                setBybitMarkets(bybitMarkets);
            });
    }, [, ]);

    const debounceMap = React.useRef(new Map());

    const debounceGetKPumpPrice = async (symbol: string, delay = 1000) => {
        const lastFetchTime = debounceMap.current.get(symbol);

        if (lastFetchTime && (Date.now() - lastFetchTime) < delay) {
            return null; // Skip fetch if called within delay period
        }

        debounceMap.current.set(symbol, Date.now());
        return await getKPumpPrice(symbol);
    };

    React.useEffect(() => {

        // console.log('KPumps BTC price update');

        if (btcPrices) {
            if (btcPrices.length > 0) {
                if (btcPrices[0].price !== krwRate) {
                    let btc = pumpsRef.current.find((p: MarketPump) => p.coin === "BTC");
                    if (btc) {
                        // console.log(`BTC prices ${btc.latest_price} ${btcPrices[0].price}`);
                        setKrwRate(btc.latest_price / btcPrices[0].price);
                    }
                }
            }
        }
    }, [btcPrices]);
    

    React.useEffect(() => {

        const fetchPrices = async () => {
        // console.log('KPumps pump update');

        pumpsRef.current = pumps;

        // Check pumps and make sure they have a reference_price
        //for (let i = 0; i < pumps.length; i++) {
            //if (!pumps[i].reference_price) {
                //pumps[i].reference_price = await getKPumpPrice(pumps[i].coin);
            //}
        //}

        // Create the two sorted lists
        let localPercentPumps = pumps.filter(p => (p.volume * p.latest_price / krwRate) > minVolume || p.coin === "BTC").sort((a, b) => b.movementMax - a.movementMax).splice(0, 12);

        // Add the current price for each new pump
        for (let i = 0; i < localPercentPumps.length; i++) {
            let symbol = localPercentPumps[i].coin;
            let price = currentPrices.find(p => p.base === symbol);
            if (price) {
                localPercentPumps[i].referencePrice = price.price;
            }
        }

        // Check we we have changed the list of symbols
        let newSymbols = localPercentPumps.map(p => p.symbol).filter(p => percentPumps.findIndex(pp => pp.symbol === p) === -1);

        //console.log('newSymbols', newSymbols);

        setPercentPumps(localPercentPumps);

        for (let i = 0; i < newSymbols.length; i++) {
            dispatch(addCurrentPrice(newSymbols[i].replace("KRW-", "")))
        }
        
/*
        // Check if this is different to what we already have
        if (percentPumps.length !== percentPumps.length) {
            
            console.log(`${new Date().valueOf()} Pump update re-render`);
        }
        else {
            let different = false;
            for (let i = 0; i < percentPumps.length; i++) {
                if (percentPumps[i].symbol !== percentPumps[i].symbol || percentPumps[i].latest_price !== percentPumps[i].latest_price || percentPumps[i].movement !== percentPumps[i].movement || percentPumps[i].volume !== percentPumps[i].volume || percentPumps[i].volume_24h !== percentPumps[i].volume_24h) {
                    different = true;
                    break;
                }
            }
            if (different) {
                setPercentPumps(percentPumps);
                console.log(`${new Date().valueOf()} Pump update re-render`);
            }
        }

        
*/
        // setPercentPumps(pumps.filter(p => (p.volume * p.latest_price / krwRate) > minVolume || p.coin === "BTC").sort((a, b) => a.movement > b.movement ? -1 : 1).splice(0, 8));
        //setVolumePumps(pumps.filter(p => (p.volume * p.latest_price / krwRate) > minVolume || p.coin === "BTC").sort((a, b) => (a.volume * a.latest_price / (krwRate ?? 1)) > (b.volume * b.latest_price / (krwRate ?? 1)) ? -1 : 1 ).splice(0, 8));
    };

    fetchPrices();
      }, [pumps]);

      React.useEffect(() => {
        //console.log('KPumps latest update');
        latestUpdateRef.current = latestUpdate;
      }, [latestUpdate]);

    React.useEffect(() => {
    
        //console.log('KPumps get all markets');

        let uri = 'https://api.upbit.com/v1/market/all';
        
        fetch(uri, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            //console.log('data', data);

            let krwMarkets:Market[] = data.filter((m: UpbitMarket) => { return m.market.startsWith('KRW-') })
                .map((m: UpbitMarket) => { return { symbol: m.market, name: m.english_name, coin: m.market.replace("KRW-", "") } as Market; });
            //console.log('krwMarkets', krwMarkets);
            setMarkets(krwMarkets);
          });
    }, [, ])

    React.useEffect(() => {
        if (markets.length > 0) {
            //const ws = new WebSocket('wss://api.upbit.com/websocket/v1');
            const ws = new WebSocket('wss://d1oqt54w09hxog.cloudfront.net/websocket/v1');
            let localPumps:MarketPump[] = []; // Local array to accumulate updates

            ws.onopen = () => {
                ws.send('[{"ticket":"UNIQUE_TICKET"},{"type":"ticker","codes":[' + markets.map((m: Market) => '"' + m.symbol + '"').join(",") + ']}]');
            };

            // Event listener for errors
            ws.onerror = (event) => {
                console.error('WebSocket error occurred:', event);
            };

            // Event listener for connection close
            ws.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };

            ws.onmessage = async (e) => {
                if (e.data instanceof Blob) {
                    let reader = new FileReader();
                    reader.onload = async (e) => {
                        if (e.target?.result) {
                            let data = JSON.parse(e.target.result as string);
                            let oldest_allowed_update = (new Date().valueOf() - 4000);
                            if (data.code && data.trade_timestamp > oldest_allowed_update) {

                                //console.log('I am here 1');

                                let pumpIndex = localPumps.findIndex(pump => pump.symbol === data.code);

                                if (pumpIndex !== -1) {

                                    //console.log('I am here 2a');

                                    // Update the existing record in the local array
                                    const pump = localPumps[pumpIndex];
                                    pump.latest_price = data.trade_price;
                                    pump.latest_timestamp = data.trade_timestamp;
                                    pump.volume += data.trade_volume;
                                    pump.volume_24h = data.acc_trade_volume_24h;
                                    pump.movement = (data.trade_price - pump.reference_price) / pump.reference_price * 100;
                                    pump.movementMax = Math.max(pump.movementMax, pump.movement);
                                    pump.referencePrice = 0;
                                } else {

                                    // console.log('I am here 2b');

                                    // Depending on the time of day either take the current value as the reference price, or go back to get the 00:00Z (approx.) price
                                    // If the current time is between 2300Z and 0000Z take the live price
                                    let referencePrice = data.trade_price;
                                    //if (DateTime.fromMillis(data.trade_timestamp).toUTC().hour < 23) {
                                        //referencePrice = await debounceGetKPumpPrice(data.code.replace("KRW-", ""));    
                                    //}

                                    // Add new pump to the local array
                                    if (referencePrice) {

                                        //console.log('I am here 2c');

                                        let pumpIndex = localPumps.findIndex(pump => pump.symbol === data.code);

                                        if (pumpIndex === -1) {
                                            localPumps.push({
                                                symbol: data.code,
                                                coin: data.code.replace("KRW-", ""),
                                                reference_price: referencePrice || 0.0,
                                                latest_price: data.trade_price,
                                                reference_timestamp: data.trade_timestamp,
                                                latest_timestamp: data.trade_timestamp,
                                                volume: data.trade_volume,
                                                volume_24h: data.acc_trade_volume_24h,
                                                movement: 0,
                                                movementMax: 0,
                                                referencePrice: 0,
                                            });
                                        }
                                    }
                                }
                            }
                            else {
                                //if (data.trade_timestamp <= oldest_allowed_update) {
                                    //console.log('What was the data?', data);
                                //}
                            }
                        }
                        else {
                            //console.log('What did I receive?', e);
                        }
                    };
                    reader.readAsText(e.data);
                }
            };

            const intervalId = setInterval(() => {
                if (localPumps.length > 0) {
                    setPumps([...localPumps]);
                    setLatestUpdate(new Date().valueOf());
                }
                else {
                    //console.log('No localPumps data, moving on');
                }
            }, 500); // Update the state every 4 seconds

            return () => {
                //console.log('WE ARE RETURNING ALREADY');
                clearInterval(intervalId);
                ws.close();
            };
        }
    }, [markets]);

    const resetPumps = () => {
        setPumps(prevPumps => {
        return prevPumps.map(r => ({
            ...r,
            reference_price: r.latest_price,
            reference_timestamp: r.latest_timestamp,
            volume: 0,
        }))});

        return;
    }

    //console.log(`${new Date().valueOf()} Rendering kpumps`)

    return (
        <Box>
            <Paper elevation={3} sx={{m: 2, p: 2}}>
                <Typography sx={{mb: 2}}>Last update: {DateTime.fromMillis(latestUpdate).toUTC().toFormat("yyyy-MM-dd HH:mm:ss")}Z ({((new Date().valueOf() - latestUpdate)/1000).toFixed(0)}s ago)</Typography>
                <Box sx={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Box>
                        <TextField 
                            variant="outlined" 
                            label="Min volume (USDT)" 
                            value={minVolume} 
                            type="number" 
                            onChange={(e) => { setMinVolume(parseInt(e.currentTarget.value) || 0) }} 
                        />
                        
                        {/*
                        <TextField sx={{ml: 2}} variant="outlined" label="KRW / USDT" value={krwRate} type="number" onChange={(e) => { setKrwRate(parseFloat(e.currentTarget.value) || 1.0) }} />
                        <TextField sx={{ml: 2}} variant="outlined" label="Source" value={tempSource} type="string" onChange={(e) => { setTempSource(e.currentTarget.value) }} onBlur={() => { setSource(tempSource) }}/>
                        <TextField sx={{ml: 2}} variant="outlined" label="Text" value={tempText} type="string" onChange={(e) => { setTempText(e.currentTarget.value) }} onBlur={() => { setText(tempText) }}/>
                        */}
                    </Box>
                    <Button 
                        variant="contained"
                        onClick={resetPumps}
                    >
                        Reset
                    </Button>
                </Box>
            </Paper>
            {
                markets.length > 0
                ? <Paper elevation={3} sx={{m: 2, p: 2}}><Typography sx={{fontWeight: 500}} variant="body2">By % movement</Typography>
                    <PumpTable items={percentPumps} krwUSDT={krwRate} sortByVolume={false} bybitMarkets={bybitMarkets} />
                    {/*<PumpCharts items={percentPumps} krwUSDT={krwRate} />*/}
                </Paper>
                : null
            }
            {
                /*
                markets.length > 0
                ? <Paper elevation={3} sx={{m: 2, p: 2}}><Typography sx={{fontWeight: 500}} variant="body2">By USDT volume</Typography><PumpTable items={volumePumps} krwUSDT={krwRate} sortByVolume={true} /></Paper>
                : null
                */
            }
        </Box>
    );
}

export default KPumps;
