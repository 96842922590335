export enum TradeType {
  BinanceNewListing,
  BinanceNewTradingPair,
  BinanceNewTradingPairBTCOnly,
  BinanceFutures,
  BinanceStaking,
  BinanceLaunchpool,
  BinanceConvert,
  BinanceSupports,
  KuCoinNewListing,
  KuCoinNewAnnouncement,
  UpbitNewListing,
  CoinbaseNewListing,
  HuobiNewListing,
  Dummy,
  ParibuNewListing,
  BithumbNewListing,
  BithumbWarningCancelled,
  ElonDogeTweet,
  BithumbWarning,
  UpBitAirdrop,
  RobinHoodNewListing,
  ProfTrader12Tweet,
  CoinbaseBlogUpdate,
  BinanceFuturesLeaderboard,
  OKXMemeToken,
  ParibuAlternateDetection,
  RobinhoodAlternateDetection,
  BinanceNewListingInnovation,
  BinanceLaunchpad,
  BinanceMiningPool,
  BithumbRebranding,
  BinanceNetworkUpgrade,
  BinanceUSNewListing,
  BinanceLaunchpoolExisting,
  BinanceNewStablecoin,
  UpbitNewKRWListing,
  BithumbNewKRWListing,
  ElonDogeTweetReply,
  BTCETFApproval,
  BinanceSupportsRedenomination,
  BinanceSupportsMerger,
  BinanceSupportsRebranding,
  BinanceSupportsRenamingOrMigration,
  BinanceSupportsAirdrop,
  BinanceLabsInvests,
  BithumbNewKRWPair,
  BybitNewPerpsExchangeDetection,
  BybitNewPerpsExchangeDetectionNoBinancePerps,
  BybitNewPerpsAnnouncementDetection,
  BybitNewPerpsAnnouncementDetectionNoBinancePerps,
  BybitNewPerpsLeakDetection,
  BybitNewPerpsLeakDetectionNoBinancePerps,
  HashKeyNewListing,
  BTCETFRejection,
  BinanceMargin,
  BakerySwapLaunchpad,
  Twitter0xENAS,
  BinanceDelisting,
  Twitter0xENASAnywhere,
  Twitter0xENASDegen,
  OKXSpotListing,
  CoinbaseDelisting,
}
