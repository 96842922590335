import React from "react";
import Toolbar from "@mui/material/Toolbar";
import {
    Alert,
    AlertTitle,
    Box,
    Divider,
    Hidden,
    Paper,
    Link,
    Snackbar,
    Typography,
    Drawer,
    useTheme,
    useMediaQuery,
    TextField,
    Button,
} from "@mui/material";

import { News, news as liveNews } from "./reducers/newsReducer";
import NewsTable from "./components/NewsTable";
import { useSelector } from "react-redux";

interface ComponentProps {
}


function NewsHistory(props: ComponentProps) {
    
    const list = useSelector(liveNews);
    
    const [news, setNews] = React.useState<News[]>([]);

    const [coin, setCoin] = React.useState<string>("");
    const [tempCoin, setTempCoin] = React.useState<string>("");

    const [source, setSource] = React.useState<string>("");
    const [tempSource, setTempSource] = React.useState<string>("");
    
    const [text, setText] = React.useState<string>("");
    const [tempText, setTempText] = React.useState<string>("");

    const [increment, setIncrement] = React.useState<number>(1);

    React.useEffect(() => {
    
        let uri = 'https://news.kryptrader.com/history';
        if (coin) {
            uri += "?coin=" + coin;
        }
    
        if (source) {
            if (coin) {
                uri += "&";
            }
            else {
                uri += "?";
            }
    
            uri += "source=" + source;
        }

        if (text) {
            if (coin || source) {
                uri += "&";
            }
            else {
                uri += "?";
            }
    
            uri += "text=" + text;
        }
    
        fetch(uri, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setNews(data as News[]);
          });
    }, [, coin, source, text, increment])

    React.useEffect(() => {

        //console.log('liveNewsDetected');

        let newNews = [...list.filter(l => news.findIndex(n => n.id === l.id) === -1)];

        //console.log('liveNewsDetected', newNews);

        if (!source && !coin && !text) {
            setNews([...news, ...list.filter(l => news.findIndex(n => n.id === l.id) === -1)]);
        }
    }, [list])

    return (
        <Box>
            <Paper elevation={3} sx={{m: 2, p: 2}}>
                <Box sx={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Box>
                        <TextField variant="outlined" label="Coin" value={tempCoin} type="string" onChange={(e) => { setTempCoin(e.currentTarget.value) }} onBlur={() => { setCoin(tempCoin) }}/>
                        <TextField sx={{ml: 2}} variant="outlined" label="Source" value={tempSource} type="string" onChange={(e) => { setTempSource(e.currentTarget.value) }} onBlur={() => { setSource(tempSource) }}/>
                        <TextField sx={{ml: 2}} variant="outlined" label="Text" value={tempText} type="string" onChange={(e) => { setTempText(e.currentTarget.value) }} onBlur={() => { setText(tempText) }}/>
                    </Box>
                    <Button 
                        variant="contained"
                        onClick={() => { setIncrement(increment + 1) }}
                    >
                        Go
                    </Button>
                </Box>
            </Paper>
            {
                news.length > 0
                ? <Paper elevation={3} sx={{m: 2, p: 2}}><NewsTable items={news} /></Paper>
                : null
            }
        </Box>
    );
}

export default NewsHistory;
