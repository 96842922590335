import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import ConfigOption from "../types/ConfigOption";
import { News } from "./newsReducer";

export interface TradeValue {
  value: number;
  direction: "Long" | "Short";
  shortcutKey: string;
  active: boolean;
  label: string;
}

export interface SymbolNewsFeed {
    symbol: string,
    news: News[],
}

export interface SymbolNewsFeedItem {
    symbol: string,
    news: News,
}

export interface TradeOption {
    label: string;
    value: number;
    shortcutKeys: string[];
    visible: boolean;
    initPercent: number,
    takeProfit: number,
    stopLoss: number,
    leverage: number | null,
    trailingStopLoss: number,
    timedSell: number
  }

// Define a type for the slice state
interface SymbolsState {
  symbols: SymbolConfig[];
  test: TradeOption,
  scalp: TradeOption,
  small: TradeOption,
  big: TradeOption,
  initPercentOptions: ConfigOption[];
  takeProfitPercentOptions: ConfigOption[];
  leverageOptions: ConfigOption[];
  stopLossPercentOptions: ConfigOption[];
  timerOptions: ConfigOption[];
}

export interface SymbolConfig {
  symbol: string;
  initialRisk: number;
  stopLoss: number;
  takeProfit: number;
  leverage: number | null;
  trailingStopLoss: number;
  timedSell: number;
  pinned: boolean;
  fullscreen: boolean;
  news: News[] | undefined;

  /** When was this symbol added so we can keep it in focus vs. new unpinned symbols */
  addedTimestamp: number;
}

const defaultSymbolSettings: SymbolConfig = {
  symbol: "",
  initialRisk: 0.03,
  stopLoss: -0.05,
  takeProfit: 0.05,
  leverage: null,
  trailingStopLoss: -0.05,
  timedSell: 180000,
  pinned: false,
  fullscreen: false,
  news: [],
  addedTimestamp: 0,
};

export interface UpdateSettingsPayload {
  symbol: string;
  property:
    | "initialRisk"
    | "stopLoss"
    | "takeProfit"
    | "leverage"
    | "trailingStopLoss"
    | "timedSell";
  option: number | null;
}

export interface AddSymbolPayload {
  symbol: string;
  pinned: boolean;
}

// Define the initial state using that type
const initialState: SymbolsState = {
  symbols: [{ ...defaultSymbolSettings, symbol: "BTC" }],
  test: {
    label: "Test",
    initPercent: 0.02,
    takeProfit: 0.03,
    leverage: 2,
    stopLoss: -0.04,
    trailingStopLoss: -0.04,
    value: 20,
    timedSell: 1800000,
    visible: true,
    shortcutKeys: ["A", "J"]
  },
  scalp: {
    label: "Scalp",
    initPercent: 0.005,
    takeProfit: 0.012,
    leverage: 5,
    stopLoss: -0.05,
    trailingStopLoss: -0.05,
    value: 200,
    timedSell: 60000,
    visible: true,
    shortcutKeys: ["S", "K"]
  },
  small: {
    label: "Small",
    initPercent: 0.01,
    takeProfit: 0.05,
    leverage: 5,
    stopLoss: -0.025,
    trailingStopLoss: -0.025,
    value: 5000,
    timedSell: 86400000,
    visible: true,
    shortcutKeys: ["D", "L"]
  },
  big: {
    label: "Big",
    initPercent: 0.015,
    takeProfit: 0.15,
    leverage: 10,
    stopLoss: -0.04,
    trailingStopLoss: -0.04,
    value: 10000,
    timedSell: 86400000,
    visible: true,
    shortcutKeys: ["F", ";"]
  },
  initPercentOptions: [
    { label: "0.75%", value: 0.0075 },
    { label: "1.25%", value: 0.0125 },
    { label: "2%", value: 0.02, default: true },
    { label: "3%", value: 0.03 },
    { label: "5%", value: 0.05 },
    { label: "Market", value: 0.2 },
  ],
  takeProfitPercentOptions: [
    { label: "1.5%", value: 0.015 },
    { label: "3%", value: 0.03 },
    { label: "5%", value: 0.05 },
    { label: "10%", value: 0.1, default: true },
    { label: "15%", value: 0.15 },
    { label: "None", value: 1.0 },
  ],
  leverageOptions: [
    { label: "1x", value: 1 },
    { label: "2x", value: 2 },
    { label: "5x", value: 5 },
    { label: "10x", value: 10 },
    { label: "20x", value: 20 },
    { label: "Max.", value: null, default: true },
  ],
  stopLossPercentOptions: [
    { label: "-3%", value: -0.03 },
    { label: "-4%", value: -0.04 },
    { label: "-5%", value: -0.05, default: true },
    { label: "-7%", value: -0.07 },
    { label: "-10%", value: -0.1 },
    { label: "None", value: -1 },
  ],
  timerOptions: [
    { label: "1 min", value: 1 * 60 * 1000 },
    { label: "3 min", value: 3 * 60 * 1000 },
    { label: "5 min", value: 5 * 60 * 1000, default: true },
    { label: "10 min", value: 10 * 60 * 1000 },
    { label: "30 min", value: 30 * 60 * 1000 },
    { label: "None", value: 24 * 60 * 60 * 1000 },
  ],
};

export const symbolsSlice = createSlice({
  name: "symbols",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addSymbol: (state, action: PayloadAction<AddSymbolPayload>) => {
      // Add if this symbol isn't already in the list
      // and there are no full screen, focussed symbols active

        if (state.symbols.findIndex((s) => s.pinned === true) === -1 || action.payload.pinned === true) {

      // Check if it's a CSV
      let symbols = [action.payload.symbol];
      if (action.payload.symbol.includes(",")) {
        symbols = action.payload.symbol.split(",");
      }

      for (let i = 0; i < symbols.length; i++) {

        //console.log(`Running addSymbol for ${symbols[i]}`);
        //console.log(`Do we need to run addSymbol for ${symbols[i]}? ${state.symbols.findIndex((s) => s.symbol === symbols[i] && s.pinned === false && action.payload.pinned === false) === -1 && state.symbols.findIndex((i) => i.fullscreen === true) === -1}`);

        if (
          state.symbols.findIndex((s) => s.symbol === symbols[i] && s.pinned === false && action.payload.pinned === false) === -1 &&
          state.symbols.findIndex((i) => i.fullscreen === true) === -1
        ) {
          state.symbols = [
            {
              initialRisk:
                state.initPercentOptions.find((o) => o.default)?.value ||
                defaultSymbolSettings.initialRisk,
              stopLoss:
                state.stopLossPercentOptions.find((o) => o.default)?.value ||
                defaultSymbolSettings.stopLoss,
              takeProfit:
                state.takeProfitPercentOptions.find((o) => o.default)?.value ||
                defaultSymbolSettings.takeProfit,
              trailingStopLoss:
                state.stopLossPercentOptions.find((o) => o.default)?.value ||
                defaultSymbolSettings.trailingStopLoss,
              leverage:
                state.leverageOptions.find((o) => o.default)?.value || null,
              timedSell:
                state.timerOptions.find((o) => o.default)?.value ||
                defaultSymbolSettings.timedSell,
              symbol: symbols[i],
              pinned: action.payload.pinned,
              fullscreen: false,
              news: undefined,
              addedTimestamp: state.symbols.filter(s => s.addedTimestamp && s.addedTimestamp > (new Date().valueOf() - 10000)).length === 0 && action.payload.pinned === false ? new Date().valueOf() : 0,
            },
            ...state.symbols
              .filter((s) => s.symbol !== symbols[i])
              .map((s) => ({
                ...s,
                pinned: action.payload.pinned === true ? false : s.pinned,
                addedTimestamp: action.payload.pinned === true ? 0 : s.addedTimestamp,
              })),
          ].sort((a, b) => (a.pinned ? -Number.MAX_VALUE : (a.addedTimestamp > (new Date().valueOf() - 10000) ? -a.addedTimestamp : 0)));
          //].sort((a, b) => (a.pinned ? -Number.MAX_VALUE : (a.addedTimestamp > (new Date().valueOf() - 10000) ? -a.addedTimestamp : 0)));
          //state.symbols.length = Math.min(state.symbols.length, 2);
        }
        }
      }
    },
    removeSymbol: (state, action: PayloadAction<string>) => {
      state.symbols = state.symbols.filter((s) => s.symbol !== action.payload);
      },
    removeAllSymbols: (state) => {
          state.symbols = [];
      },
    togglePinning: (state, action: PayloadAction<string>) => {
      let tempState = state.symbols.map((s) => ({
        ...s,
        pinned: s.symbol === action.payload ? !s.pinned : false,
        addedTimestamp: 0,
      }));
      state.symbols = [
        ...tempState.filter((s) => s.pinned),
        ...tempState.filter((s) => s.pinned === false),
      ];
    },
    toggleFullscreen: (state, action: PayloadAction<string>) => {
      let tempState = state.symbols.map((s) => ({
        ...s,
        fullscreen: s.symbol === action.payload ? !s.fullscreen : false,
      }));
      // Only show one symbol when something is 'fullscreen'
      state.symbols = tempState.filter(
        (t) => t.fullscreen || t.symbol === action.payload
      );
    },
    updateSymbol: (
      state,
      action: PayloadAction<UpdateSettingsPayload | UpdateSettingsPayload[]>
    ) => {
      let copy: SymbolConfig[] = [...state.symbols];

      let array = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      let index = copy.findIndex((c) => c.symbol === array[0].symbol);

      for (let i = 0; i < array.length; i++) {
        /*
        console.log(
          `Updating ${array[i].property} for ${array[i].symbol} to ${array[i].option}`
        );*/

        switch (array[i].property) {
          case "initialRisk":
            copy[index].initialRisk = array[i].option || 0;
            break;
          case "stopLoss":
            copy[index].stopLoss = array[i].option || 0;
            break;
          case "takeProfit":
            copy[index].takeProfit = array[i].option || 0;
            break;
          case "leverage":
            copy[index].leverage = array[i].option;
            break;
          case "trailingStopLoss":
            copy[index].trailingStopLoss = array[i].option || 0;
            break;
          case "timedSell":
            copy[index].timedSell = array[i].option || 0;
            break;
        }
      }

      state.symbols = copy;
    },
    setInitPercentOptions: (state, action: PayloadAction<ConfigOption[]>) => {
      state.initPercentOptions = JSON.parse(
        JSON.stringify(action.payload)
      ) as ConfigOption[];
    },
    setTakeProfitPercentOptions: (
      state,
      action: PayloadAction<ConfigOption[]>
    ) => {
      state.takeProfitPercentOptions = JSON.parse(
        JSON.stringify(action.payload)
      ) as ConfigOption[];
    },
    setLeverageOptions: (state, action: PayloadAction<ConfigOption[]>) => {
      state.leverageOptions = JSON.parse(
        JSON.stringify(action.payload)
      ) as ConfigOption[];
    },
    setStopLossPercentOptions: (
      state,
      action: PayloadAction<ConfigOption[]>
    ) => {
      state.stopLossPercentOptions = JSON.parse(
        JSON.stringify(action.payload)
      ) as ConfigOption[];
    },
    setTimerOptions: (state, action: PayloadAction<ConfigOption[]>) => {
      state.timerOptions = JSON.parse(
        JSON.stringify(action.payload)
      ) as ConfigOption[];
    },
    setTest: (state, action: PayloadAction<TradeOption>) => {
        state.test = action.payload;
    },
    setScalp: (state, action: PayloadAction<TradeOption>) => {
        state.scalp = action.payload;
    },
    setSmall: (state, action: PayloadAction<TradeOption>) => {
        state.small = action.payload;
    },
    setBig: (state, action: PayloadAction<TradeOption>) => {
        state.big = action.payload;
    },
    addSymbolNews: (state, action: PayloadAction<SymbolNewsFeed>) => {
        //if (state.symbols.findIndex((s) => s.pinned === true) === -1) {
            let tempSymbols = JSON.parse(
                JSON.stringify(state.symbols)
            ) as SymbolConfig[];
            
            let targetIndex = tempSymbols.findIndex(s => s.symbol === action.payload.symbol);
            if (targetIndex > -1) {
                tempSymbols[targetIndex].news = action.payload.news;
                state.symbols = tempSymbols;
            }
        //}
    },
    addSymbolNewsItem: (state, action: PayloadAction<SymbolNewsFeedItem>) => {
        if (state.symbols.findIndex((s) => s.pinned === true) === -1) {
            if (state.symbols.find(s => s.symbol === action.payload.symbol)) {
                let tempSymbols = JSON.parse(
                    JSON.stringify(state.symbols)
                ) as SymbolConfig[];
                
                let targetIndex = tempSymbols.findIndex(s => s.symbol === action.payload.symbol);
                if (targetIndex > -1) {
                    tempSymbols[targetIndex].news = [action.payload.news, ...tempSymbols[targetIndex].news || []];
                    state.symbols = tempSymbols;
                }
            }       
        }
    }
  },
});

export const {
  addSymbol,
  removeSymbol,
  updateSymbol,
  togglePinning,
  toggleFullscreen,
  setInitPercentOptions,
  setTakeProfitPercentOptions,
  setLeverageOptions,
  setStopLossPercentOptions,
  setTimerOptions,
  setTest,
  setScalp,
  setSmall,
  setBig,
  addSymbolNews,
  addSymbolNewsItem,
} = symbolsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const list = (state: RootState) => state.symbols.symbols;
export const selectInitPercentOptions = (state: RootState) =>
  state.symbols.initPercentOptions;
export const selectTakeProfitPercentOptions = (state: RootState) =>
  state.symbols.takeProfitPercentOptions;
export const selectLeverageOptions = (state: RootState) =>
  state.symbols.leverageOptions;
export const selectStopLossPercentOptions = (state: RootState) =>
  state.symbols.stopLossPercentOptions;
export const selectTimerOptions = (state: RootState) =>
  state.symbols.timerOptions;
export const selectTradeIsFullscreen = (state: RootState) =>
  state.symbols.symbols.findIndex((i) => i.fullscreen === true) > -1;
export const selectTradeIsPinnned = (state: RootState) =>
  state.symbols.symbols.findIndex((i) => i.pinned === true) > -1;
export const selectTest = (state: RootState) =>
  state.symbols.test;
export const selectScalp = (state: RootState) =>
  state.symbols.scalp;
export const selectSmall = (state: RootState) =>
  state.symbols.small;
export const selectBig = (state: RootState) =>
  state.symbols.big;

export default symbolsSlice.reducer;
